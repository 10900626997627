import axios from "axios"
import { Loading } from "element-ui";
import { Message } from 'element-ui';

// 全局loading
let loading;
// 全局配置
const axiosAdmin = axios.create({
  headers: { 'Content-Type': 'application/json' },
})
// 请求拦截
axiosAdmin.interceptors.request.use(
  (config) => {
    if (localStorage.accessToken) {
      config.headers.qxauth = 'bearer ' + localStorage.accessToken;
    }
    showLoading();
    return config;
  },
  (err) => {
    hideLoading();
    return Promise.reject(err);
  }
)
// 响应拦截
axiosAdmin.interceptors.response.use(
  (res) => {
    hideLoading();
    if (res.status === 200) {
      return res.data;
    } else {
      return res;
    }
  },
  (err) => {
    hideLoading();
    if (err && err.response) {
      switch (err.response.status) {
        case 400: err.message = '请求错误(400)'; break;
        case 401: err.message = '请求错误(401)'; break;
        case 403: err.message = '拒绝访问(403)'; break;
        case 404: err.message = '请求出错(404)'; break;
        case 408: err.message = '请求超时(408)'; break;
        case 500: err.message = '服务器错误(500)'; break;
        case 501: err.message = '服务未实现(501)'; break;
        case 502: err.message = '网络错误(502)'; break;
        case 503: err.message = '服务不可用(503)'; break;
        case 504: err.message = '网络超时(504)'; break;
        case 505: err.message = 'HTTP版本不受支持(505)'; break;
        default: err.message = `连接出错(${err.response.status})!`;
      }
    } else {
      err.message = '连接服务器失败!'
    }
    Message.error(err.message);
    if (err.response.status == 401) {
      loading = Loading.service({
        lock: true,
        text: "鉴权认证失败 请求错误(401)",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.9)",
      });
      setTimeout(() => {
        localStorage.clear()
        location.reload()
      }, 1000);
    }
    return Promise.reject(err); // 详细错误信息 err.response
  }
)
/* 显示loading */
function showLoading() {
  loading = Loading.service({
    lock: true,
    text: "请稍等",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.9)",
  });
}
/* 隐藏loading */
function hideLoading() {
  loading.close();
}
// 导出
export default axiosAdmin