<template>
  <div id="app">
    <div v-if="$route.name == 'Default' || $route.name == 'Login'">
      <router-view />
    </div>
    <div v-else>
      <Menu />
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {
    
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #f0f0f0;
  color: #606266;
}
.list {
  border-radius: 10px;
  margin-top: 20px;
}
.pagination {
  margin-top: 10px;
  .btn-prev {
    border-radius: 10px 0 0 10px;
  }
  .btn-next {
    border-radius: 0 10px 10px 0;
  }
}
.formWrap {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.formName {
  line-height: 40px;
  margin-right: 10px;
}
.formBody {
  margin-right: 10px;
  width: 193px !important;
}
.formBodyPlus {
  margin-right: 10px;
  width: 500px !important;
}
.mr10 {
  margin-right: 10px;
}


// .el-dialog {
//   display: flex;
//   display: -ms-flex;
//   flex-direction: column;
//   -ms-flex-direction: column;
//   margin: 0 !important;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   max-height: calc(100% -30px);
//   max-width: calc(100% - 30px);
// }
// .el-dialog .el-dialog__body {
//   max-height: 100%;
//   flex: 1;
//   -ms-flex: 1 1 auto;
//   overflow-y: auto;
//   overflow-x: hidden;
// }

</style>
