import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: function () {
      return import('../views/Login.vue')
    }
  },
  {
    path: '/Device',
    name: 'Device',
    component: function () {
      return import('../views/Device.vue')
    }
  },
  {
    path: '/Manufacturer',
    name: 'Manufacturer',
    component: function () {
      return import('../views/Manufacturer.vue')
    }
  },
  {
    path: '/Datatype',
    name: 'Datatype',
    component: function () {
      return import('../views/Datatype.vue')
    }
  },
  {
    path: '/Console',
    name: 'Console',
    component: function () {
      return import('../views/Console.vue')
    }
  },
  {
    path: '/User',
    name: 'User',
    component: function () {
      return import('../views/User.vue')
    }
  },
  {
    path: '/Open',
    name: 'Open',
    component: function () {
      return import('../views/Open.vue')
    }
  },
  {
    path: '/Rule',
    name: 'Rule',
    component: function () {
      return import('../views/Rule.vue')
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if ( (to.name !== 'Login') && !localStorage.accessToken) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
