<template>
  <div class="menu">
    <div class="menu-header">
      <img src="../assets/logo.png" />
      <h1>晴鑫 iot 智慧物联管理平台</h1>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item v-if="isAdmin" index="Manufacturer">厂商管理</el-menu-item>
      <el-menu-item v-if="isAdmin" index="Datatype">物联数据模型</el-menu-item>
      <el-menu-item v-if="!isAdmin" index="Console">控制台</el-menu-item>
      <el-menu-item index="Device">设备管理</el-menu-item>
      <el-menu-item v-if="isAdmin" index="Open">数据分发</el-menu-item>
      <el-menu-item v-if="isAdmin" index="Rule">分发规则</el-menu-item>
      <el-menu-item v-if="isAdmin" index="User">用户管理</el-menu-item>
      <el-menu-item index="Login">退出</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    params: String,
  },
  data() {
    return {};
  },
  computed: {
    isAdmin: function () {
      return localStorage.getItem("roleLevel") == 1;
    },
    activeIndex: function () {
      return this.$route.name;
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      if (key == "Login") {
        this.$confirm("此操作将退出登陆, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.clear();
            this.$router.push({
              name: "Login",
            });
            this.$message({
              type: "success",
              message: "已退出",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
      else if (this.$route.name != key) {
        this.$router.push({
          name: key,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.menu-header {
  background-color: #000000;
  height: 100px;
  display: flex;
  align-items: center;
  img {
    margin-left: 20px;
    width: 60px;
    height: 60px;
  }
  h1 {
    margin-left: 20px;
    font-size: 30px;
    color: #ffffff;
  }
}
</style>
